var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "conditions-block", attrs: { id: "mortgage" } },
    [
      _c(
        "content-wrapper-block",
        [
          _c("bread-crumbs"),
          _c("page-title", { attrs: { white: "" } }, [
            _vm._v(" Условия покупки "),
          ]),
          _c(
            "div",
            { staticClass: "conditions-block__items" },
            _vm._l(_vm.conditionsArray, function (slide, index) {
              return _c("condition", {
                key: index,
                attrs: {
                  number: slide.number,
                  title: slide.title,
                  description: slide.description,
                  link: slide.link,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }