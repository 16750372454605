<template>
  <div class="condition">
    <div class="condition-number">
      <span>{{ number }}</span>
    </div>
    <div class="condition-content">
      <div class="condition-content__title">
        {{ title }}
      </div>
      <div class="condition-content__description">
        {{ description }}
        <router-link
          class="condition-content__description_link"
          :to="{ name: 'OnlineShopping' }"
        >
          {{ link }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Condition",
  props: {
    number: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.condition {
  display: flex;
  color: #fff;

  &-number {
    font-size: 40px;
    font-weight: 600;
    border-right: 1px solid #918f8f;
    transform: rotate(45deg);
    width: 34px;
    height: 45px;
    margin-top: -13px;
    overflow: hidden;

    span {
      display: block;
      transform: rotate(-45deg);
      margin: 6px 0 0 12px;
    }
  }

  &-content {
    margin-left: 20px;

    &__title {
      font-size: 20px;
      letter-spacing: 0.05em;
    }

    &__description {
      font-family: Rubik;
      font-style: normal;
      font-size: 12px;
      color: #918f8f;
      margin-top: 5px;
      width: 220px;
      line-height: 1.4;
      letter-spacing: 0.03em;

      &_link {
        color: #fff;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
</style>
