<template>
  <div id="mortgage" class="conditions-block">
    <content-wrapper-block>
      <bread-crumbs />
      <page-title white> Условия покупки </page-title>
      <div class="conditions-block__items">
        <condition
          v-for="(slide, index) in conditionsArray"
          :key="index"
          :number="slide.number"
          :title="slide.title"
          :description="slide.description"
          :link="slide.link"
        />
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageTitle from "@/components/elements/PageTitle";
import Condition from "@/components/elements/Condition";
import { mapState } from "vuex";
import BreadCrumbs from "@/components/elements/BreadCrumbs";

export default {
  name: "MainConditionBlock",
  components: {
    BreadCrumbs,
    ContentWrapperBlock,
    PageTitle,
    Condition,
  },
  props: {
    /*
    *
    news: [
      {
        id: Number,
        title: String,
        content: String,
        date: String,
      }
    ]
    */
    conditions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      conditionsState: (state) => state.conditions,
    }),
    conditionsArray() {
      return this.conditions.length > 0
        ? this.conditions
        : this.conditionsState;
    },
  },
};
</script>

<style lang="scss" scoped>
.conditions-block {
  background: #231f20;

  &__items {
    margin-top: 20px;

    @media screen and (min-width: $screen-mini) {
      display: flex;
      margin-top: 40px;
    }
    .condition {
      margin-bottom: 20px;
    }
  }
}
</style>
