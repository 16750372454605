<template>
  <div class="conditions">
    <condition-block />
    <mortgage-block />
    <best-apartments-block />
    <callback-block id="callback" id-formblock="question-block__callback" />
  </div>
</template>

<script>
import ConditionBlock from "@/components/blocks/MainConditionBlock";
import MortgageBlock from "@/components/blocks/MortgageBlock";
import CallbackBlock from "@/components/blocks/CallbackBlock";
import BestApartmentsBlock from "@/components/blocks/BestApartmentsBlock";

export default {
  name: "ConditionPage",
  components: {
    BestApartmentsBlock,
    ConditionBlock,
    MortgageBlock,
    CallbackBlock,
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Несколько выгодных способов покупки апартаментов в ЖК MainStreet на выбор.",
      },
    ],
  },
};
</script>

<style lang="scss" scoped>
.conditions {
  background: #f4f4f4;
  &-block {
    padding-bottom: 40px;
  }

  .mortgage-block {
    padding-bottom: 25px;
  }

  .offers {
    padding: 28px 0 50px 0;
  }
}
</style>
