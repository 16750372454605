var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "condition" }, [
    _c("div", { staticClass: "condition-number" }, [
      _c("span", [_vm._v(_vm._s(_vm.number))]),
    ]),
    _c("div", { staticClass: "condition-content" }, [
      _c("div", { staticClass: "condition-content__title" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c(
        "div",
        { staticClass: "condition-content__description" },
        [
          _vm._v(" " + _vm._s(_vm.description) + " "),
          _c(
            "router-link",
            {
              staticClass: "condition-content__description_link",
              attrs: { to: { name: "OnlineShopping" } },
            },
            [_vm._v(" " + _vm._s(_vm.link) + " ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }